/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import axios from "axios";
import { PageLink, PageTitle } from "../../_metronic/layout/core";
import { useLocation, useParams } from "react-router-dom";
import React from "react";



const CallbackPage: FC = () => {

  function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  const { platform } = useParams<{ platform?: string }>();

  const query = useQuery()

  async function getData() {
    const code = query.get('code')

    await axios.post('callback', { platform, code })

    console.log(code)
  }

  // async function handleSubmit() {
  //   setSavingSettings(true);

  //   // let result = await axios.put(`settings`, formData);
  //   // console.log(result);
  //   getData();

  //   setSavingSettings(false);
  // }

  useEffect(() => {
    getData();
  }, []);

  const widgetsBreadCrumbs: Array<PageLink> = [
    {
      title: "Configurações",
      path: "/settings",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={widgetsBreadCrumbs}>
        Conexão de plataforma
      </PageTitle>
      {/* begin::Row */}
      <div className="row">
        {/* begin::Col */}

        {/* begin::Col */}
        <div className="col-xl-12">
          <div className={`card card-xl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className="card-header border-0 pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder fs-3 mb-1">
                  Conexão de plataforma
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">
                  Conectando plataforma {platform}
                </span>
              </h3>
            </div>
            <div className="card-body py-3">
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { CallbackPage };
